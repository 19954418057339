<template>
  <div class="overflow-hidden">
    <div class="row mb-5 d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <v-img src="@/assets/images/banners/superior_login.svg" alt="login" />
      </div>
    </div>

    <div class="row mt-5">
      <div class="col-12 col-md-5 col-lg-3 mx-auto">
        <div class="mx-auto login">
          <div>
            <h2>Iniciar Sesión</h2>
            <p>
              Espacio exclusivo para expositores, por <br />
              favor ingresa tus credenciales.
            </p>
          </div>
          <div>
            <form novalidate autocomplete="off" @submit.prevent="signIn">
              <validation-observer ref="form">
                <validation-provider
                  v-slot="{ errors }"
                  name="Usuario"
                  rules="required"
                >
                  <v-text-field
                    class="rounded-clg"
                    vid="username"
                    v-model="model.username"
                    type="username"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Usuario"
                    required
                  ></v-text-field>
                </validation-provider>

                <validation-provider
                  vid="password"
                  v-slot="{ errors }"
                  name="Contraseña"
                  rules="required"
                >
                  <v-text-field
                    class="rounded-clg"
                    v-model="model.password"
                    :type="showPassword ? 'text' : 'password'"
                    ref="password"
                    outlined
                    dense
                    :error-messages="errors"
                    label="Contraseña"
                    required
                  >
                    <v-icon slot="append" @click="showPassword = !showPassword">
                      {{ showPassword ? "mdi-eye-off" : "mdi-eye" }}
                    </v-icon>
                  </v-text-field>
                </validation-provider>
                <v-btn
                  color="secondary"
                  type="submit"
                  class="black--text mb-2 rounded-lg text-capitalize"
                  block
                >
                  Ingresar al espacio
                </v-btn>
                <v-btn
                  block
                  text
                  small
                  plain
                  class="text-lowercase"
                  @click="$router.push('/password-reset')"
                >
                  ¿<span class="text-uppercase">H</span>as olvidado tu
                  contraseña?
                </v-btn>
              </validation-observer>
            </form>
          </div>
        </div>
      </div>
    </div>

    <div class="row d-flex justify-center">
      <div class="col-12 col-md-5 col-lg-3">
        <v-img src="@/assets/images/banners/inferior_login.svg" alt="login" />
      </div>
    </div>
  </div>
</template>

<script>
import sessionMixin from "@/mixins/sessionMixin";
import notificationMixin from "@/mixins/notificationMixin";
import loaderMixin from "@/mixins/loaderMixin";

export default {
  name: "Login",
  data: () => ({
    model: {
      username: "",
      password: "",
    },
    showPassword: false,
  }),
  mixins: [sessionMixin, notificationMixin, loaderMixin],
  methods: {
    signIn() {
      this.$refs.form.validate().then((valid) => {
        if (valid) {
          this.showLoader();
          this.model.fairId = this.$store.getters["fairs/fair"]?.id;
          this.login(this.model)
            .then(() => {
              if (this.$store.getters["auth/userInfo"]?.user?.fair?.slug) {
                this.$router.replace(
                  "fairs/" +
                    this.$store.getters["auth/userInfo"].user.fair.slug +
                    "/visitors"
                );
              } else {
                this.$router.replace("/");
              }
              this.hideLoader();
            })
            .catch((error) => {
              this.hideLoader();
              if (error.response && error.response.status === 401) {
                this.$refs.form.errors.password.push(
                  "Credenciales incorrectas"
                );
              }
            });
        }
      });
    },
  },
};
</script>
<style scoped>
.login {
  width: 80%;
}
</style>
